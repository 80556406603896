<template>
  <div class="login-box">
    <h3 class="header-login">热注管线流转管理系统</h3>
    <el-form ref="ruleForm" :model="ruleForm"
             status-icon
             label-width="80px"
             class="demo-ruleForm">
      <el-form-item prop="username">
        <el-input class="input-login" v-model="ruleForm.username" autocomplete="off" type="text" placeholder="用户名" />
      </el-form-item>
      <el-form-item prop="password">
        <el-input class="input-login" v-model="ruleForm.password" autocomplete="off" type="password" placeholder="密码" />
      </el-form-item>
      <el-form-item style="margin-left: 0">
        <el-button class="login-button" style="margin-left: 0" type="primary" @click="handleSubmit">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import jsCookie from 'js-cookie';
export default {
  name: "Login",
  data(){
    return{
      logining:false,
      ruleForm: {
        username: '',
        password: ''
      },
    }
  },
  methods: {
    handleSubmit(){
      this.$refs.ruleForm.validate((valid) => {
        if(valid){
          const _this = this
          jsCookie.set('username',this.ruleForm.username)
          jsCookie.set('password',this.ruleForm.password)
          console.log(_this.ruleForm)
          axios.get('https://rzgxxt.top:8181/staff/login', {params:_this.ruleForm}).then(function (resp) {
            if(resp.data.code === -1){
              _this.$alert('管理员权限的该用户名不存在', '提示', {
                confirmButtonText: '确定'
              })
            }
            if(resp.data.code === -2){
              _this.$alert('密码错误', '提示', {
                confirmButtonText: '确定'
              })
            }
            if(resp.data.code === 0){
              console.log(_this.ruleForm.username)
              localStorage.setItem("token", _this.ruleForm.username);
              _this.$router.push({
                name:"HomeView"
              })
            }
          })
        }
      })
    }
  }
};

</script>
<style lang='scss' scoped>
.header-login {
  float: left;
  height: 100px;
  width: 100%;
  background-color: rgba($color: rgb(66, 66, 66), $alpha: 0.3);
  text-align: center;
  line-height: 100px;
  font-weight: bold;
  font-size: 46px;
  letter-spacing: 2px;
  color: rgb(41, 41, 41);
}
.login-box {
  content: "";
  display: table;
  clear: both;
  height: 100vh;
  width: 100%;
  background-color: #5884a4;
  .demo-ruleForm {
    font-weight: bold;
    width: 310px;
    height: 170px;
    margin: 300px auto;
    padding: 40px 80px 30px 0;
    background-color: rgba($color: rgb(255, 255, 255), $alpha: 0.3);
  }
  .login-button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
</style>