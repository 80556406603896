<template>
  <el-container>
    <el-main style="padding-top: 0">
      <router-view></router-view>
      <div>
        <!-- 按钮区域 -->
        <el-row class="mb-4" style="float: left; margin-top: 15px; margin-bottom: 15px">
          <el-button type="success" style="width: 100px; border-radius: 20px" plain @click="addDialogVisible=true">新增</el-button>
          <el-button type="danger" style="width: 100px; border-radius: 20px" plain @click="deleteSelectItem(multipleSelection)">删除</el-button>
        </el-row>

        <!-- el-form结构 -->
        <el-form ref="ruleFormRef" :model="ruleForm">
          <!-- 选择查询参数下拉框 -->
          <el-form-item class="mb-4" style="float: left;margin-top: 15px;margin-bottom: 15px;margin-left: 15px;">
            <el-select v-model="ruleForm.key" placeholder="请选择查询参数">
              <el-option label="编号" value="id"></el-option>
              <el-option label="姓名" value="name"></el-option>
              <el-option label="归属部门" value="department"></el-option>
              <el-option label="职务" value="job"></el-option>
              <el-option label="管理端权限" value="ORDER_ADMIN"></el-option>
            </el-select>
          </el-form-item>
          <!-- 搜索框 & 搜索按钮 -->
          <el-form-item class="mb-4" style="float: right; margin-top: 15px; margin-bottom: 15px">
            <el-button @click="submitForm('ruleForm')" type="plain" style="margin-left: 10px; border-radius: 20px; float: right">搜索</el-button>
            <input v-model="ruleForm.value" style="height:36px;border: 1px solid rgb(197, 197, 197);float: right;border-radius: 20px;padding-left: 15px;" />
          </el-form-item>
          <!-- 日期区间 级联 -->
          <el-form-item class="mb-4" style="margin: 15px;float: right;">
            <div class="block">
              <el-date-picker
                  v-model="ruleForm.date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  @change="datePicker"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <!-- 新增员工 弹出对话框 -->
      <el-dialog title="添加员工" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
        <el-form ref="addFormRef" :model="addRuleForm" :rules="rules" label-width="70px">
          <el-form-item label="姓名" prop="name" label-width="100px">
            <el-input v-model="addRuleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="tel" label-width="100px">
            <el-input v-model="addRuleForm.tel"></el-input>
          </el-form-item>
          <el-form-item label="登录账号" prop="username" label-width="100px">
            <el-input v-model="addRuleForm.username"></el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="password" label-width="100px">
            <el-input v-model="addRuleForm.password"></el-input>
          </el-form-item>
          <el-form-item label="部门类型" prop="departmentType" label-width="100px">
            <el-select filterable v-model="addRuleForm.departmentType" @change="getAvailableDepartment(addRuleForm.departmentType)" placeholder="请选择">
              <el-option
                  v-for="item in departmentTypeList"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="归属部门" prop="department" label-width="100px">
            <el-select filterable v-model="addRuleForm.department" placeholder="请选择">
              <el-option
                  v-for="item in departmentList"
                  :label="item.departmentName"
                  :value="item.departmentName">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职务" prop="job" label-width="100px">
            <el-select v-model="addRuleForm.job" placeholder="请选择">
              <el-option
                  v-for="item in order_department_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管理端权限" prop="orderAdmin" label-width="100px">
            <el-select v-model="addRuleForm.orderAdmin" placeholder="请选择">
              <el-option
                  v-for="item in order_admin_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="add('addRuleForm')">确 定</el-button>
            </span>
      </el-dialog>

      <!-- 编辑员工 弹出对话框 -->
      <el-dialog title="编辑员工" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
        <el-form ref="editFormRef" :model="editRuleForm" :rules="rules" label-width="70px">
          <el-form-item label="姓名" prop="name" label-width="100px">
            <el-input v-model="editRuleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="tel" label-width="100px">
            <el-input v-model="editRuleForm.tel"></el-input>
          </el-form-item>
          <el-form-item label="登录账号" prop="username" label-width="100px">
            <el-input v-model="editRuleForm.username"></el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="password" label-width="100px">
            <el-input v-model="editRuleForm.password"></el-input>
          </el-form-item>
          <el-form-item label="部门类型" prop="departmentType" label-width="100px">
            <el-select filterable v-model="editRuleForm.departmentType" @change="clearAvailableDepartment(editRuleForm.departmentType)" placeholder="请选择">
              <el-option
                  v-for="item in departmentTypeList"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="归属部门" prop="department" label-width="100px">
            <el-select filterable v-model="editRuleForm.department" placeholder="请选择">
              <el-option
                  v-for="item in departmentList"
                  :label="item.departmentName"
                  :value="item.departmentName">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职务" prop="job" label-width="100px">
            <el-select v-model="editRuleForm.job" placeholder="请选择">
              <el-option
                  v-for="item in order_department_options"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管理端权限" prop="orderAdmin" label-width="100px">
            <el-select v-model="editRuleForm.orderAdmin" placeholder="请选择">
              <el-option
                  v-for="item in order_admin_options"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="edit('editRuleForm')">确 定</el-button>
        </span>
      </el-dialog>

      <!-- table -->
      <div style="border-radius: 20px;overflow: hidden;display: block;height: 82.5vh;width: 100%;">
        <el-table stripe show-header ref="multipleTableRef" :data="tableData" height="88vh" style="width: 100%"
                  @selection-change="handleSelectionChange" :row-key="getRowkeys">
          <el-table-column type="selection" :reserve-selection=true width="55px" />
          <el-table-column prop="id" label="编号" sortable width="90px" />
          <el-table-column prop="name" label="姓名" sortable width="110px" />
          <el-table-column prop="tel" label="电话" sortable width="150" />
          <el-table-column prop="department" label="归属部门" sortable width="auto" />
          <el-table-column prop="job" label="职务" sortable width="80px" />
          <el-table-column prop="addTime" label="添加时间" sortable width="170px" />
          <el-table-column prop="orderAdmin" label="管理权" sortable width="100px" />
          <!-- NEW -->
          <el-table-column prop="username" label="用户名" sortable width="auto" />
          <!-- NEW -->
          <el-table-column prop="password" label="密码" sortable width="auto" />
          <el-table-column fixed="right" label="操作" width="100px" show-overflow-tooltip>
            <template scope="scope">
              <el-button link size="mini" type="primary" @click="ifEdit(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页 -->
      <el-pagination style="margin-top: 20px;float: right"
                     background
                     layout="prev, pager, next"
                     :page-size="pageSize"
                     :total="total"
                     :current-page.sync="currentPage"
                     @current-change="page">
      </el-pagination>
    </el-main>
  </el-container>
</template>

<script>
import {pipeManagerDate} from "@/assets/js/checkjs/pipeManagerChecker";

export default {
  data () {
    return {
      tableData: [],
      // tableData: null,
      multipleSelection: [],
      // currentPage：当前在多少页
      currentPage: 1,
      // pageSize：每页展示多少条数据
      pageSize: 14,
      // pageSize: 1,
      // total：总数据量
      total: null,
      key: '',
      value: '',
      // 新增
      addRuleForm: {
        name: '',
        tel: '',
        departmentType: '',
        department: '',
        job: '',
        orderAdmin: '',
        username: '',
        password: ''
      },
      // 编辑
      editRuleForm: {
        id: '',
        name: '',
        tel: '',
        departmentType: '',
        department: '',
        oldDepartment:'',
        job: '',
        orderAdmin: '',
        username: '',
        password: ''
      },
      // 新增 - 部门级联选择
      departmentTypeList: [{
        value: '库房',
        label: '库房'
      }, {
        value: '运输部门类型',
        label: '运输部门类型'
      }, {
        value: '施工队',
        label: '施工队'
      }, {
        value: '具体井号',
        label: '具体井号'
      }],
      departmentList:'',
      // 新增选项 - 选择职务
      order_department_options: [{
        value: '员工',
        label: '员工'
      }, {
        value: '主管',
        label: '主管'
      }],
      // 新增选项 - 选择是否有管理端权限
      order_admin_options: [{
        value: '是',
        label: '是'
      }, {
        value: '否',
        label: '否'
      }],
      // 新增 - 窗口的开关
      addDialogVisible: false,
      // 编辑 - 窗口的开关
      editDialogVisible: false,
      ruleForm: {
        startTime: '',
        endTime: '',
        date: '',
        key: '',
        value: '',
        page: '',
        size: 14,
        // size: 1
      },
      // 表单校验
      rules:{
        tel:[
          { required: true, message: '请您输入员工电话！', trigger: 'blur' },
        ],
        name:[
          { required: true, message: '请您输入员工姓名！', trigger: 'blur' },
        ],
        departmentType:[
          { required: true, message: '请您选择员工的归属部门类型！', trigger: 'blur' },
        ],
        department:[
          { required: true, message: '请您选择员工的归属部门！', trigger: 'blur' },
        ],
        job:[
          { required: true, message: '请您选择员工的职务！', trigger: 'blur' },
        ],
        orderAdmin:[
          { required: true, message: '请您选择员工的管理端权限！', trigger: 'blur' },
        ],
        username:[
          { required: true, message: '请您输入该员工的用户名！', trigger: 'blur' },
          {
            pattern: /^[0-9a-zA-Z]*$/g,
            message: '用户名只能输入字母和数字！',
            trigger: 'blur'
          },
          { min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur' }
        ],
        password:[
          { required: true, message: '请您输入该员工的密码！', trigger: 'blur' },
          {
            pattern: /^[0-9a-zA-Z]*$/g,
            message: '密码只能输入字母和数字！',
            trigger: 'blur'
          },
          { min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    // 编辑时 若改变部门类型，清空部门名称要求重新输入
    clearAvailableDepartment(departmentType){
      this.editRuleForm.department = '';
      const _this = this
      axios.get('https://rzgxxt.top:8181/department/getDepartmentList/' + departmentType).then(function (resp) {
        _this.departmentList = resp.data.data
      })
    },
    // 获取部门
    getAvailableDepartment(departmentType){
      this.addRuleForm.department = '';
      const _this = this
      axios.get('https://rzgxxt.top:8181/department/getDepartmentList/' + departmentType).then(function (resp) {
        _this.departmentList = resp.data.data
      })
    },
    // table多选存储容器
    handleSelectionChange(valueSelected) {
      // 存储选中的数据至 => this.multipleSelection
      this.multipleSelection = valueSelected;
    },
    // 跨分页保持批量选中 row.key 依赖方法
    getRowkeys:function (row){
      return row.id;
    },
    // 页面显示
    page(currentPage){
      const _this = this
      if(_this.ruleForm.value === ''&& _this.ruleForm.startTime === ''){
        axios.get('https://rzgxxt.top:8181/staff/list/'+currentPage+'/'+_this.pageSize).then(function (resp) {
          _this.tableData = resp.data.data.data
          _this.total = resp.data.data.total
        })
      } else {
        _this.ruleForm.page = _this.currentPage
        axios.get('https://rzgxxt.top:8181/staff/search',{params:_this.ruleForm}).then(function (resp) {
          _this.tableData = resp.data.data.data
          _this.total = resp.data.data.total
        })
      }
    },
    // 添加
    add: function (formName) {
      const _this = this
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          axios.post('https://rzgxxt.top:8181/staff/add', _this.addRuleForm).then(function (resp) {
            if (resp.data.code === 0) {
              console.log(resp.data)
              _this.$alert('添加成功', '', {
                confirmButtonText: '确定',
                callback: action => {
                  location.reload()
                }
              });
            } else {
              _this.$alert('添加失败', '', {
                confirmButtonText: '添加失败了，请重新添加或联系系统管理员',
                callback: action => {
                  location.reload()
                }
              });
            }
          })
        }
      });
    },
    // 编辑
    edit: function (formName){
      const _this = this
      this.$refs.editFormRef.validate((valid) => {
        if (valid) {
          console.log(_this.editRuleForm)
          axios.put('https://rzgxxt.top:8181/staff/update',_this.editRuleForm).then(function (resp) {
            if(resp.data.code === 0){
              _this.$alert('修改成功', '', {
                confirmButtonText: '确定',
                callback: action => {
                  location.reload()
                }
              });
            } else {
              _this.$alert('修改失败', '', {
                confirmButtonText: '修改失败了，请重新添加或联系系统管理员',
                callback: action => {
                  location.reload()
                }
              });
            }
          })
        }
      });
    },
    // 编辑 信息回填
    ifEdit: function (row) {
      console.log(row)
      // 编辑 数据回填
      this.editRuleForm.oldDepartment = row.department
      this.editRuleForm.name = row.name
      this.editRuleForm.tel = row.tel
      this.editRuleForm.username = row.username
      this.editRuleForm.password = row.password
      this.editRuleForm.department = row.department
      this.editRuleForm.departmentType = row.departmentType
      this.editRuleForm.orderAdmin = row.orderAdmin
      this.editRuleForm.job = row.job
      this.editRuleForm.id = row.id
      // 编辑 窗口打开
      this.editDialogVisible = true
    },
    // 搜索 - 员工
    submitForm(formName) {
      const _this = this
      // 每次搜索前，重置日期选择器
      if (this.ruleForm.date===null) {
        this.ruleForm.date = ''
        this.ruleForm.endTime = ''
        this.ruleForm.startTime = ''
      }
      //让翻页复原
      _this.currentPage = 1
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          const _this = this
          _this.ruleForm.page = _this.currentPage
          axios.get('https://rzgxxt.top:8181/staff/search',{params:_this.ruleForm}).then(function (resp) {
            _this.tableData = resp.data.data.data
            _this.total = resp.data.data.total
          })
        }
      });
    },
    // 搜索 - 日期获取器
    datePicker(picker) {
      this.ruleForm.startTime = picker[0].toString()
      this.ruleForm.endTime = picker[1].toString()
      // console.log(this.ruleForm.startTime)
      // console.log(this.ruleForm.endTime)
    },
    // 监听添加用户对话框关闭 -> 重置对话框内的内容
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    // 监听编辑 对话框关闭 -> 重置对话框内的内容
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
    // 删除
    deleteSelectItem(valueSelected) {
      const _this = this
      let ids = this.multipleSelection.map(v => v.id)//存储选中的数据
      this.$confirm("此操作将永久删除数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        console.log(ids)
        axios.post('https://rzgxxt.top:8181/staff/deleteByIds', ids).then(function (resp) {
          if (resp.data.code === 0) {
            _this.$alert('删除成功', '', {
              confirmButtonText: '确定',
              callback: action => {
                location.reload()
              }
            });
          } else {
            _this.$alert('删除失败', '', {
              confirmButtonText: '删除失败了，重新删除或联系系统管理员',
              callback: action => {
                location.reload()
              }
            });
          }
        });
      })
    },
  },

  // 钩子函数
  created() {
    const _this = this
    axios.get('https://rzgxxt.top:8181/staff/list/1/'+_this.pageSize).then(function (resp) {
      console.log(resp.data.data.data)
      _this.tableData = resp.data.data.data
      _this.total = resp.data.data.total
    })
  },
}
</script>

<style scoped>
/*主体*/
.el-main {
  padding-top: 0;
  width: 86vw;
  height: auto;
  background-color: #ecf5ff;
}
/*搜索框和按钮的父容器*/
.top {
  padding-top: 5vh;
  height: 5vh;
  width: 100%;
  justify-content: center;
}
/* 搜索框样式 */
.demo-input-size {
  height: auto;
  width: auto;
  overflow: hidden;
  border-radius: 20px;
  float: right;
}
</style>