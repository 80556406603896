import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PipeManager from '../views/PipeManager'
import DistributeManager from '../views/DistributeManager'
import TransformManager from '../views/TransformManager'
import StaffManager from '../views/StaffManager'
import DepartmentManager from '../views/DepartmentManager'
import Exit from '../views/Exit'
import Login from '../views/Login'

Vue.use(VueRouter)

const routes = [
  {
    path: '/HomeView',
    name: 'HomeView',
    component: HomeView,
    meta: {
      // 登录校验
      needLogin: true
    },
    redirect: '/PipeManager',
    children:[
      {
        path: '/PipeManager',
        name: 'PipeManager',
        component: PipeManager,
        meta: {
          // 登录校验
          needLogin: true
        },
      },
      {
        path: '/DistributeManager',
        name: 'DistributeManager',
        component: DistributeManager,
        meta: {
          // 登录校验
          needLogin: true
        },
      },
      {
        path: '/TransformManager',
        name: 'TransformManager',
        component: TransformManager,
        meta: {
          // 登录校验
          needLogin: true
        },
      },
      {
        path: '/StaffManager',
        name: 'StaffManager',
        component: StaffManager,
        meta: {
          // 登录校验
          needLogin: true
        },
      },
      {
        path: '/DepartmentManager',
        name: 'DepartmentManager',
        component: DepartmentManager,
        meta: {
          // 登录校验
          needLogin: true
        },
      },
      {
        path: '/Exit',
        name: 'Exit',
        component: Exit,
        meta: {
          // 登录校验
          needLogin: true
        },
      },
    ]
  },
  {
    path: '/',
    redirect:"/Login",
    name: 'Login',
    component: Login
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

