<template>
  <el-container>
    <el-main>
      <router-view></router-view>
      <div>
        <!-- 按钮区域 -->
        <el-row class="mb-4" style="float: left; margin-top: 15px; margin-bottom: 15px">
          <el-button type="success" style="width: 100px; border-radius: 20px" plain @click="addDialogVisible=true">新增</el-button>
          <el-button type="danger" style="width: 100px; border-radius: 20px" plain @click="deleteSelectItem(multipleSelection)">删除</el-button>
          <el-button type="primary" style="width: 150px; border-radius: 20px" plain @click="multipleDownloadQRCode(multipleSelection)">下载二维码</el-button>
        </el-row>
        <!-- el-form结构 -->
        <el-form ref="ruleFormRef" :model="ruleForm">
          <!-- 选择查询参数下拉框 -->
          <el-form-item class="mb-4" style="float: left;margin-top: 15px;margin-bottom: 15px;margin-left: 15px;">
            <el-select v-model="ruleForm.key" placeholder="请选择查询参数">
              <el-option label="ID" value="ID"></el-option>
              <el-option label="钢管编号" value="PIPE_ID"></el-option>
              <el-option label="材质" value="NAME"></el-option>
              <el-option label="长度" value="LENGTH"></el-option>
              <el-option label="流转次数" value="TRANSFORM_TIMES"></el-option>
              <el-option label="维护日期" value="FIX_TIME"></el-option>
              <el-option label="规格型号" value="MODEL"></el-option>
              <el-option label="状态" value="STATUS"></el-option>
              <el-option label="当前所在部门" value="DEPARTMENT_NAME"></el-option>
            </el-select>
          </el-form-item>
          <!-- 搜索框 & 搜索按钮 -->
          <el-form-item class="mb-4" style="float: right; margin-top: 15px; margin-bottom: 15px">
            <el-button @click="submitForm('ruleForm')" type="plain" style="margin-left: 10px; border-radius: 20px; float: right">搜索</el-button>
            <input v-model="ruleForm.value" style="height:36px;border: 1px solid rgb(197, 197, 197);float: right;border-radius: 20px;padding-left: 15px;" />
          </el-form-item>
          <!-- 日期区间 级联 -->
          <el-form-item class="mb-4" style="margin: 15px;float: right;">
            <div class="block">
              <el-date-picker
                  v-model="ruleForm.date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  @change="datePicker"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <!-- 单个下载二维码 -->
      <el-dialog title="下载二维码" :visible.sync="downloadDialogVisible" width="480px">
        <div class="qr_code">
          <h1 class="header_title">您要下载的钢管编号为：{{ downloadForm }}</h1>
          <img id="2canvas" :src="'data:image/jpg;base64,' + downloadFormBase64" alt="" />
        </div>
        <div slot="footer">
          <el-button @click="downloadDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleDownloadqrCode">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 批量下载二维码 -->
      <el-dialog title="下载二维码" :visible.sync="multipleDownloadDialogVisible" width="480px">
        <div class="qr_code">
          <img id="multipleDownloadID" :src="'data:image/jpg;base64,' + downloadFormBase64" alt="" />
        </div>
      </el-dialog>

      <!-- 新增钢管 弹出对话框 -->
      <el-dialog title="添加管材" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
        <el-form ref="addFormRef" :model="addRuleForm" :rules="rules" label-width="70px">
          <el-form-item label="钢管编号" prop="pipeId" label-width="100px">
            <el-input v-model="addRuleForm.pipeId" placeholder="请输入新增钢管名称..."></el-input>
          </el-form-item>
          <el-form-item label="名称" prop="name" label-width="100px">
            <el-input v-model="addRuleForm.name" placeholder="请输入新增钢管名称..."></el-input>
          </el-form-item>
          <el-form-item label="长度" prop="length" label-width="100px">
            <el-input v-model="addRuleForm.length" placeholder="请输入新增钢管长度..."></el-input>
          </el-form-item>
          <el-form-item label="规格型号" prop="model" label-width="100px">
            <el-input v-model="addRuleForm.model" placeholder="请输入新增钢管规格型号..."></el-input>
          </el-form-item>
          <el-form-item label="生产日期" prop="produceTime" label-width="100px">
            <el-input v-model="addRuleForm.produceTime" placeholder="请输入新增钢管生产日期..."></el-input>
          </el-form-item>
          <el-form-item label="检修日期" prop="fixTime" label-width="100px">
            <el-input v-model="addRuleForm.fixTime" placeholder="请输入新增钢管生产日期..."></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="add('addRuleForm')">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 编辑钢管 弹出对话框 -->
      <el-dialog title="编辑管材" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
        <el-form ref="editFormRef" :model="editRuleForm" :rules="rules" label-width="70px">
          <el-form-item label="钢管编号" prop="pipeId" label-width="100px">
            <el-input v-model="editRuleForm.pipeId" placeholder="请输入新的钢管编号..."></el-input>
          </el-form-item>
          <el-form-item label="名称" prop="name" label-width="100px">
            <el-input v-model="editRuleForm.name" placeholder="请输入新的钢管名称..."></el-input>
          </el-form-item>
          <el-form-item label="长度" prop="length" label-width="100px">
            <el-input v-model="editRuleForm.length" placeholder="请输入新的钢管长度..."></el-input>
          </el-form-item>
          <el-form-item label="规格型号" prop="model" label-width="100px">
            <el-input v-model="editRuleForm.model" placeholder="请输入新的钢管规格型号..."></el-input>
          </el-form-item>
          <el-form-item label="生产日期" prop="produceTime" label-width="100px">
            <el-input v-model="editRuleForm.produceTime" placeholder="请输入新的钢管生产日期..."></el-input>
          </el-form-item>
          <el-form-item label="检修日期" prop="fixTime" label-width="100px">
            <el-input v-model="editRuleForm.fixTime" placeholder="请输入新增钢管检测日期..."></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="edit('editRuleForm')">确 定</el-button>
        </span>
      </el-dialog>

      <!-- table -->
      <div style="border-radius: 20px;overflow: hidden;display: block;height: 82.5vh;width: 100%;">
        <el-table stripe show-header ref="multipleTableRef" v-if="tableData" :data="tableData" height="88vh" style="width: 100%"
                  @selection-change="handleSelectionChange" id="img" :row-key="getRowkeys">
          <el-table-column :reserve-selection=true type="selection" width="55px"/>
          <el-table-column prop="id" label="ID" sortable width="100px" />
          <el-table-column prop="pipeId" label="钢管编号" sortable width="auto" />
          <el-table-column prop="name" label="名称" sortable width="auto" />
          <el-table-column prop="length" label="长度" sortable width="auto" />
          <el-table-column prop="transformTimes" label="流转次数" sortable width="auto" />
          <el-table-column prop="fixTime" label="维护日期" sortable width="auto" />
          <el-table-column prop="model" label="规格型号" sortable width="auto" />
          <el-table-column prop="produceTime" label="生产日期" sortable width="100px" />
          <el-table-column prop="departmentName" label="当前所在部门" sortable width="auto" />
          <el-table-column prop="status" label="状态" sortable width="100px" />
          <el-table-column fixed="right" label="二维码" width="100px" show-overflow-tooltip>
            <template slot-scope="scope">
              <img id="3canvas" alt="" :src="'data:image/png;base64,'+scope.row.qrcode" style="padding:0;margin:0;width:6.55vh;height:6.55vh" />
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="200px" show-overflow-tooltip>
            <template scope="scope">
              <el-button size="mini" type="primary" @click="ifEdit(scope.row)">编辑</el-button>
              <el-button size="mini" type="primary" @click="ifDownload(scope.row)">下载二维码</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页 -->
      <el-pagination style="margin-top: 20px;float: right"
                     background
                     layout="prev, pager, next"
                     :page-size="pageSize"
                     :total="total"
                     :current-page.sync="currentPage"
                     @current-change="page">
      </el-pagination>
    </el-main>
  </el-container>
</template>

<script>
import html2canvas from "html2canvas";
import {pipeManagerDate} from "@/assets/js/checkjs/pipeManagerChecker";

// table多选存储容器
export default {
  data () {
    return {
      tableData: [],
      // table容器
      multipleSelection: [],
      // currentPage：当前在多少页
      currentPage: 1,
      // pageSize：每页展示多少条数据
      pageSize: 8,
      // total：总数据量
      total: null,
      key: '',
      value: '',
      // 新增 - 窗口的开关
      addDialogVisible: false,
      // 编辑 - 窗口的开关
      editDialogVisible: false,
      // 下载 - 窗口的开关
      downloadDialogVisible: false,
      // 批量下载 - 窗口的开关
      multipleDownloadDialogVisible: false,
      // 新增
      addRuleForm: {
        pipeId: '',
        name: '',
        length:'',
        model: '',
        produceTime: '',
        fixTime: ''
      },
      // 编辑
      editRuleForm: {
        id:'',
        pipeId: '',
        name: '',
        length:'',
        model: '',
        produceTime: '',
        fixTime: ''
      },
      // 搜索
      ruleForm: {
        date: '',
        startTime: '',
        endTime: '',
        key: '',
        value: '',
        page: '',
        size: 8
      },
      //下载
      multipleDownload: [],
      multipleDownloadForm: '',
      multipleDownloadFormBase64: '',
      downloadForm: '',
      downloadFormBase64: '',
      // 表单校验
      rules:{
        produceTime:[
          { required: true, message: '请输入正确格式的日期：xxxx-xx-xx，如：2023-03-09', trigger: 'blur' },
          { validator: pipeManagerDate}
        ],
        fixTime: [
          { required: true, message: '请输入正确格式的日期：xxxx-xx-xx，如：2023-03-09', trigger: 'blur' },
          { validator: pipeManagerDate}
        ],
        pipeId:[
          { required: true, message: '请您输入钢管编号！', trigger: 'blur' },
        ],
        name:[
          { required: true, message: '请您输入钢管名称！', trigger: 'blur' },
        ],
        length:[
          { required: true, message: '请您输入钢管长度！', trigger: 'blur' },
        ],
        model:[
          { required: true, message: '请您输入钢管型号', trigger: 'blur' },
        ]
      }
    }
  },
  methods: {
    // table多选存储容器
    handleSelectionChange(valueSelected) {
      // 存储选中的数据至 => this.multipleSelection
      this.multipleSelection = valueSelected;
      // 存储选中的数据至 => this.downloadForm.pipes
      this.multipleDownloadForm = valueSelected;
      console.log(this.multipleDownloadForm)
    },
    // 跨分页保持批量选中 row.key 依赖方法
    getRowkeys:function (row){
      return row.id;
    },
    // 页面显示
    page(currentPage){
      const _this = this
      if(_this.ruleForm.value === ''&& _this.ruleForm.startTime === ''){
        axios.get('https://rzgxxt.top:8181/pipe/list/'+currentPage+'/'+_this.pageSize).then(function (resp) {
          _this.tableData = resp.data.data.data
          _this.total = resp.data.data.total
        })
      } else{
        _this.ruleForm.page = _this.currentPage
        axios.get('https://rzgxxt.top:8181/pipe/search',{params:_this.ruleForm}).then(function (resp) {
          _this.tableData = resp.data.data.data
          _this.total = resp.data.data.total
        })
      }
    },

    // 添加
    add: function (addRuleForm) {
      const _this = this
      console.log(_this.addRuleForm)
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          axios.post('https://rzgxxt.top:8181/pipe/add', _this.addRuleForm).then(function (resp) {
            if (resp.data.code === 0) {
              _this.$alert('添加成功', '', {
                confirmButtonText: '确定',
                callback: action => {
                  _this.$router.push('/pipeManager')
                  location.reload()
                }
              });
            } else {
              _this.$alert('添加失败', '', {
                confirmButtonText: '添加失败了，请重新添加或联系系统管理员',
                callback: action => {
                  _this.$router.push('/pipeManager')
                  location.reload()
                }
              });
            }
          })
        }
      });
    },
    // 编辑
    edit: function (editRuleForm) {
      const _this = this
      this.$refs.editFormRef.validate((valid) => {
        if (valid) {
          console.log(_this.editRuleForm)
          axios.put('https://rzgxxt.top:8181/pipe/update',_this.editRuleForm).then(function (resp) {
            if(resp.data.code === 0){
              _this.$alert('修改成功', '', {
                confirmButtonText: '确定',
                callback: action => {
                  _this.$router.push('/pipeManager')
                  location.reload()
                }
              });
            } else {
              _this.$alert('修改失败', '', {
                confirmButtonText: '修改失败了，请重新添加或联系系统管理员',
                callback: action => {
                  _this.$router.push('/pipeManager')
                  location.reload()
                }
              });
            }
          })
        }
      });
    },
    // 删除
    deleteSelectItem() {
      const _this = this
      // 存储选中的数据
      let ids = this.multipleSelection.map(v => v.id)
      this.$confirm("此操作将永久删除数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        console.log(ids)
        axios.post('https://rzgxxt.top:8181/pipe/deleteByIds', ids).then(function (resp) {
          if (resp.data.code === 0) {
            _this.$alert('删除成功', '', {
              confirmButtonText: '确定',
              callback: action => {
                location.reload()
              }
            });
          } else {
            _this.$alert('删除失败', '', {
              confirmButtonText: '删除失败了，请重新删除或联系系统管理员',
              callback: action => {
                location.reload()
              }
            });
          }
        });
      })
    },
    // 编辑 信息回填
    ifEdit: function (row) {
      console.log(row.id)
      this.editRuleForm.pipeId = row.pipeId
      this.editRuleForm.name = row.name
      this.editRuleForm.model = row.model
      this.editRuleForm.produceTime = row.produceTime
      this.editRuleForm.fixTime = row.fixTime
      this.editRuleForm.id = row.id
      // 编辑 窗口打开
      this.editDialogVisible = true
    },
    // 单体下载二维码初始化器 - 初始化数据
    ifDownload: function (row) {
      this.downloadFormBase64 = row.qrcode
      this.downloadForm = row.pipeId
      this.downloadDialogVisible = true
    },
    // 单体下载
    async handleDownloadqrCode() {
      html2canvas(document.getElementById("2canvas")).then((canvas) => {
        var pageData = canvas.toDataURL("image/png", 1.0); //第二个参数可以调节下载图片的清晰度
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = pageData;
        link.setAttribute("download", this.downloadForm + ".png");
        document.body.appendChild(link);
        link.click();
      });
      this.downloadDialogVisible = false
    },
    // 批量下载
    multipleDownloadQRCode: async function () {
      //存储 选中数据的 id
      let ids = this.multipleSelection.map(v => v.pipeId)
      //存储 选中数据的 base64
      let base64s = this.multipleSelection.map(v => v.qrcode)//存储选中的数据
      for (let i = 0; i < this.multipleSelection.length; i++) {
        this.downloadFormBase64 = base64s[i]
        this.downloadForm = ids[i]
        await html2canvas(document.getElementById("3canvas")).then((canvas) => {
          // 关键步骤！
          var pageData = 'data:image/png;base64,' + this.downloadFormBase64
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = pageData;
          link.setAttribute("download", this.downloadForm + ".png");
          document.body.appendChild(link);
          link.click();
        });
      }
    },
    // 搜索
    submitForm: function (formName) {
      const _this = this
      // 每次搜索前，重置日期选择器
      if (this.ruleForm.date===null) {
        this.ruleForm.date = ''
        this.ruleForm.endTime = ''
        this.ruleForm.startTime = ''
      }
      // 让翻页复原
      _this.currentPage = 1
      this.$refs.ruleFormRef.validate((valid) => {
        console.log(valid)
        if (valid) {
          _this.ruleForm.page = _this.currentPage
          console.log(this.ruleForm)
          axios.get('https://rzgxxt.top:8181/pipe/search', {params:_this.ruleForm}).then(function (resp) {
            _this.tableData = resp.data.data.data
            _this.total = resp.data.data.total
          })
        }
      });
    },
    // 搜索 - 日期获取器
    datePicker(picker) {
      this.ruleForm.startTime = picker[0].toString()
      this.ruleForm.endTime = picker[1].toString()
    },
    // 监听添加 对话框关闭 -> 重置对话框内的内容
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    // 监听编辑 对话框关闭 -> 重置对话框内的内容
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
  },

  // 钩子函数
  created() {
    const _this = this
    this.multipleDownloadDialogVisible = true
    this.multipleDownloadDialogVisible = false
    axios.get('https://rzgxxt.top:8181/pipe/list/1/'+_this.pageSize).then(function (resp) {
      console.log(resp.data.data.data)
      _this.tableData = resp.data.data.data
      _this.total = resp.data.data.total
    })
  },
}
</script>

<style scoped>
/*主体*/
.el-main {
  padding-top: 0;
  width: 86vw;
  height: auto;
  background-color: #ecf5ff;
}
/*搜索框和按钮的父容器*/
.top {
  padding-top: 5vh;
  height: 5vh;
  width: 100%;
  justify-content: center;
}
/* 搜索框样式 */
.demo-input-size {
  height: auto;
  width: auto;
  overflow: hidden;
  border-radius: 20px;
  float: right;
}
/*.el-table {*/
/*  border-bottom: 1px solid #a4afc2;*/
/*  margin: 0 auto;*/
/*}*/
/*::v-deep.el-table th {*/
/*  border-bottom: 1px solid #a4afc2 !important;*/
/*}*/
/*::v-deep.el-table td {*/
/*  border-bottom: 1px solid #a4afc2 !important;*/
/*}*/
/*::v-deep .el-table td,.building-top .el-table th.is-leaf {*/
/*  border-bottom:  1px solid #a4afc2;*/
/*}*/
</style>