import { render, staticRenderFns } from "./DepartmentManager.vue?vue&type=template&id=b31790a0&scoped=true"
import script from "./DepartmentManager.vue?vue&type=script&lang=js"
export * from "./DepartmentManager.vue?vue&type=script&lang=js"
import style0 from "./DepartmentManager.vue?vue&type=style&index=0&id=b31790a0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b31790a0",
  null
  
)

export default component.exports