<template>
  <el-container>
    <el-main style="padding-top: 0">
      <router-view></router-view>
      <div>
        <!-- el-form结构 -->
        <el-form ref="ruleFormRef" :model="ruleForm">
          <!-- 选择查询参数下拉框 -->
          <el-form-item class="mb-4" style="float: left;margin-top: 15px;margin-bottom: 15px;margin-left: 0;">
            <el-select v-model="ruleForm.key" placeholder="请选择查询参数">
              <el-option label="记录号" value="id"></el-option>
              <el-option label="钢管编号" value="PIPE_ID_ID"></el-option>
              <el-option label="操作" value="OPERATION"></el-option>
              <el-option label="操作人" value="OPERATION_PERSON"></el-option>
            </el-select>
          </el-form-item>
          <!-- 搜索框 & 搜索按钮 -->
          <el-form-item class="mb-4" style="float: right; margin-top: 15px; margin-bottom: 15px">
            <el-button @click="submitForm('ruleForm')" type="plain" style="margin-left: 10px; border-radius: 20px; float: right">搜索</el-button>
            <input v-model="ruleForm.value" style="height:36px;border: 1px solid rgb(197, 197, 197);float: right;border-radius: 20px;padding-left: 15px;" />
          </el-form-item>
          <!-- 日期区间 级联 -->
          <el-form-item class="mb-4" style="margin: 15px;float: right;">
            <div class="block">
              <el-date-picker
                  v-model="ruleForm.date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  @change="datePicker"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <!-- table -->
      <div style="border-radius: 20px;overflow: hidden;display: block;height: 82.5vh;width: 100%;">
        <el-table ref="multipleTableRef" :data="tableData" height="88vh" style="width: 100%">
          <el-table-column prop="id" label="记录号" sortable width="100px" />
          <el-table-column prop="pipeIdId" label="钢管编号" sortable width="auto" />
          <el-table-column prop="currentDepartmentName" label="当前所在部门" sortable width="200px" />
          <el-table-column prop="operation" label="操作状态" sortable width="auto" />
          <el-table-column prop="operationTime" label="操作时间" sortable width="200px" />
          <el-table-column prop="userDepartmentName" label="操作人部门" sortable width="200px" />
          <el-table-column prop="operationPerson" label="操作人" sortable width="200px" show-overflow-tooltip />
        </el-table>
      </div>

      <!-- 分页 -->
      <el-pagination style="margin-top: 20px;float: right"
                     background
                     layout="prev, pager, next"
                     :page-size="pageSize"
                     :total="total"
                     :current-page.sync="currentPage"
                     @current-change="page">
      </el-pagination>
    </el-main>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      // currentPage：当前在多少页
      currentPage: 1,
      // pageSize：每页展示多少条数据
      pageSize: 14,
      // total：总数据量
      total: null,
      key: '',
      value: '',
      ruleForm: {
        startTime: '',
        endTime: '',
        date: '',
        key: '',
        value: '',
        page: '',
        size: 14
      }
    }
  },
  methods: {
    // 页面显示
    page(currentPage){
      const _this = this
      if(_this.ruleForm.value === ''){
        axios.get('https://rzgxxt.top:8181/transform/list/'+currentPage+'/'+_this.pageSize).then(function (resp) {
          _this.tableData = resp.data.data.data
          _this.total = resp.data.data.total
        })
      } else {
        _this.ruleForm.page = _this.currentPage
        axios.get('https://rzgxxt.top:8181/transform/search',{params:_this.ruleForm}).then(function (resp) {
          _this.tableData = resp.data.data.data
          _this.total = resp.data.data.total
        })
      }
    },
    // 搜索
    submitForm(formName) {
      const _this = this
      // 每次搜索前，重置日期选择器
      if (this.ruleForm.date===null) {
        this.ruleForm.date = ''
        this.ruleForm.endTime = ''
        this.ruleForm.startTime = ''
      }
      //让翻页复原
      _this.currentPage = 1
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          _this.ruleForm.page = _this.currentPage
          console.log(this.ruleForm)
          axios.get('https://rzgxxt.top:8181/transform/search',{params:_this.ruleForm}).then(function (resp) {
            _this.tableData = resp.data.data.data
            _this.total = resp.data.data.total
          })
        }
      });
    },
    // 搜索 - 日期获取器
    datePicker(picker) {
      this.ruleForm.startTime = picker[0].toString()
      this.ruleForm.endTime = picker[1].toString()
      // console.log(this.ruleForm.startTime)
      // console.log(this.ruleForm.endTime)
    },
  },

  // 钩子函数
  created() {
    const _this = this
    axios.get('https://rzgxxt.top:8181/transform/list/1/'+_this.pageSize).then(function (resp) {
      console.log(resp.data.data.data)
      _this.tableData = resp.data.data.data
      _this.total = resp.data.data.total
    })
  },
}
</script>