export function pipeManagerDate(rule, value, callback) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;//正则表达式校验
    if (!value) {
        callback("日期不可为空！");
    } else if (!regex.test(value)) {
        callback("请输入正确格式的日期：xxxx-xx-xx，如：2023-03-09'");
    } else {
        callback();
    }
}
