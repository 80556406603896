<template>
  <div class="exit">
    <a style="text-decoration: none;">
      <!-- 这里只能使用style，不能使用class，需要优先级 -->
      <el-button type="success" style="text-decoration: none;border-radius:20px;margin-right:auto;
      margin-left:auto;width:20vw;height:30vh;font-size:35px" plain @click="logout">安全退出</el-button>
    </a>
  </div>
</template>

<script>
export default{
  methods: {
    // 退出登录不必反复确认
    logout: function () {
      // 清除状态保持
      console.log(localStorage)
      window.localStorage.clear()
      console.log(localStorage)
      // 状态保持清除后刷新页面
      window.location.reload()
      // localStorage.removeItem('HomeView')
      // _this.$router.replace({path: '/login'})
    }
  }
}
</script>

<style scoped>
.exit {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 35vh;
  margin-bottom: auto;
  text-align: center;
  line-height: 100%;
}
</style>