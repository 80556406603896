<template>
  <div style="width:100% !important;min-width: 1300px !important;" class="common-layout">
    <el-container class="container">
      <el-row style="background-color: #81beff;" class="tac">
        <el-col style="min-width: 100%;" :span="12">
          <h5 style="min-width: 100%;" class="mb-2">{{showUserName}}</h5>
          <!-- default-active：能够设定默认的左侧aside路由设定第几个 -->
          <el-menu style="min-width: 100%;" default-active="0" class="el-menu-vertical-demo" router>
            <el-menu-item style="min-width: 100%;" index="/PipeManager">
              <i class="el-icon-receiving" style="color:black"></i>
              <span>钢管管理</span>
            </el-menu-item>
            <el-menu-item style="min-width: 100%;" index="/DistributeManager">
              <i class="el-icon-box" style="color:black"></i>
              <span>分配管理</span>
            </el-menu-item>
            <el-menu-item style="min-width: 100%;" index="/TransformManager">
              <i class="el-icon-date" style="color:black"></i>
              <span>流转管理</span>
            </el-menu-item>
            <el-menu-item style="min-width: 100%;" index="/StaffManager">
              <i class="el-icon-user" style="color:black"></i>
              <span>员工管理</span>
            </el-menu-item>
            <el-menu-item style="min-width: 100%;" index="/DepartmentManager">
              <i class="el-icon-house" style="color:black"></i>
              <span>部门管理</span>
            </el-menu-item>
            <el-menu-item style="min-width: 100%;" index="/Exit">
              <i class="el-icon-switch-button" style="color:black"></i>
              <span>退出系统</span>
            </el-menu-item>
          </el-menu>
        </el-col>
      </el-row>
      <!-- 主体部分 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import jsCookie from "js-cookie";
export default{
  data(){
    return {
      admin:''
    }
  },
  computed:{
    showUserName(){
      return jsCookie.get('username')
    }
  },
  created() {
    let admin = JSON.parse(window.localStorage.getItem('HomeView'))
    this.admin = admin
    this.$store.state.admin = admin
  }
}
</script>

<style scoped>
/*左侧aside-box补色，移开时也不变色*/
.el-menu-item {
  background-color: #81beff !important;
}
.el-col-12 {
  background-color: #81beff;
  min-width: 100%;
  flex: 0 0 100%;
}
/*左侧aside-menu边框修复*/
.el-menu {
  border-right: 0;
  min-width: 100%;
}
/*左侧el-menu-item样式*/
.el-menu-item {
  width: 14vw;
  background-color: #81beff;
}
/*左侧aside样式*/
.mb-2 {
  background-color: #81beff;
  min-width: 100%;
  height: 10vh;
  text-align: center;
  line-height: 10vh;
  font-size: 23px;
  font-weight: bold;
  color: rgb(72, 84, 102);
}
/*主体*/
.el-main {
  padding-top: 0;
  width: 86vw;
  height: 100vh;
  background-color: #ecf5ff;
}
.container {
  width: 100%;
  min-width: 100vw;
}
</style>
