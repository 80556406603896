<template>
  <el-container>
    <el-main style="padding-top: 0">
      <router-view></router-view>
      <div>
        <!-- 按钮区域 -->
        <el-row class="mb-4" style="float: left; margin-top: 15px; margin-bottom: 15px">
          <el-button type="success" style="width: 100px; border-radius: 20px" plain @click="addDialogVisible=true">新增</el-button>
          <el-button type="danger" style="width: 100px; border-radius: 20px" plain @click="deleteSelectItem(multipleSelection)">删除</el-button>
        </el-row>
        <!-- el-form结构 -->
        <el-form ref="ruleFormRef" :model="ruleForm">
          <!-- 选择查询参数下拉框 -->
          <el-form-item class="mb-4" style="float: left;margin-top: 15px;margin-bottom: 15px;margin-left: 15px;">
            <el-select ref="ruleFormRef" v-model="ruleForm.key" placeholder="请选择查询参数">
              <el-option label="部门编号" value="id"></el-option>
              <el-option label="部门名称" value="DEPARTMENT_NAME"></el-option>
              <el-option label="部门类型" value="DEPARTMENT_TYPE"></el-option>
            </el-select>
          </el-form-item>
          <!-- 搜索框 & 搜索按钮 -->
          <el-form-item class="mb-4" style="float: right; margin-top: 15px; margin-bottom: 15px">
            <el-button @click="submitForm('ruleForm')" type="plain" style="margin-left: 10px; border-radius: 20px; float: right">搜索</el-button>
            <input v-model="ruleForm.value" style="height:36px;border: 1px solid rgb(197, 197, 197);float: right;border-radius: 20px;padding-left: 15px;" />
          </el-form-item>
          <!-- 日期区间 级联 -->
          <el-form-item class="mb-4" style="margin: 15px;float: right;">
            <div class="block">
              <el-date-picker
                  v-model="ruleForm.date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  @change="datePicker"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <!-- 新增部门 弹出对话框 -->
      <el-dialog title="添加部门" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
        <el-form ref="addFormRef" :model="addRuleForm" :rules="rules" label-width="70px">
          <el-form-item label="部门名称" prop="departmentName" label-width="100px">
            <el-input v-model="addRuleForm.departmentName" placeholder="请输入新增部门名称..."></el-input>
          </el-form-item>
          <el-form-item label="部门类型" prop="departmentType" label-width="100px">
            <el-select filterable v-model="addRuleForm.departmentType" placeholder="请选择部门类型...">
              <el-option
                  v-for="item in department_type_options"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="add('addRuleForm')">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 编辑部门 弹出对话框 -->
      <el-dialog title="编辑部门" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
        <el-form ref="editFormRef" :model="editRuleForm" :rules="rules" label-width="70px">
          <el-form-item label="部门名称" prop="departmentName" label-width="100px">
            <el-input v-model="editRuleForm.departmentName" placeholder="请输入新的部门名称..."></el-input>
          </el-form-item>
          <el-form-item label="部门类型" prop="departmentType" label-width="100px">
            <el-select v-model="editRuleForm.departmentType" placeholder="请选择">
              <el-option
                  v-for="item in department_type_options"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="edit('editRuleForm')">确 定</el-button>
        </span>
      </el-dialog>

      <!-- table -->
      <div style="border-radius: 20px;overflow: hidden;display: block;height: 82.5vh;width: 100%;">
        <el-table stripe show-header ref="multipleTableRef" :data="tableData" height="88vh" style="width: 100%"
                  @selection-change="handleSelectionChange" :row-key="getRowkeys">
          <el-table-column type="selection" :reserve-selection=true width="55px" />
          <el-table-column prop="id" label="部门编号" sortable width="150px" />
          <el-table-column prop="departmentName" label="部门名称" sortable width="auto" />
          <!-- NEW -->
          <el-table-column prop="departmentType" label="部门类型" sortable width="250px" />
          <el-table-column prop="numOfStaff" label="人数" width="130px" sortable />
          <el-table-column prop="addTime" label="添加时间" sortable width="200px" />
          <el-table-column fixed="right" label="操作" width="100px" show-overflow-tooltip>
            <template scope="scope">
              <el-button link size="mini" type="primary" @click="ifEdit(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页 -->
      <el-pagination style="margin-top: 20px;float: right"
                     background
                     layout="prev, pager, next"
                     :page-size="pageSize"
                     :total="total"
                     :current-page.sync="currentPage"
                     @current-change="page">
      </el-pagination>
    </el-main>
  </el-container>
</template>

<script>
// table多选存储容器
import {pipeManagerDate} from "@/assets/js/checkjs/pipeManagerChecker";

export default {
  data () {
    return {
      tableData: [],
      // 多选容器
      // tableData: null,
      // currentPage：当前在多少页
      currentPage: 1,
      // pageSize：每页展示多少条数据
      pageSize: 14,
      // total：总数据量
      total: null,
      key: '',
      value: '',
      // multipleSelection：多选容器
      multipleSelection: [],
      // 新增 - 窗口的开关
      addDialogVisible: false,
      // 编辑 - 窗口的开关
      editDialogVisible: false,
      // ruleForm：搜索使用的数据
      ruleForm: {
        startTime: '',
        endTime: '',
        date: '',
        key: '',
        value: '',
        page: '',
        size: 14
      },
      // 新增
      addRuleForm: {
        departmentName: '',
        departmentType: ''
      },
      // 编辑
      editRuleForm: {
        id:'',
        departmentName: '',
        departmentType: ''
      },
      // 新增 & 编辑 - 选择部门类型
      department_type_options: [{
        value: '库房',
        label: '库房'
      }, {
        value: '运输部门类型',
        label: '运输部门类型'
      }, {
        value: '施工队',
        label: '施工队'
      }, {
        value: '具体井号',
        label: '具体井号'
      }],
      // 表单校验
      rules:{
        departmentName:[
          { required: true, message: '请您输入部门名称！', trigger: 'blur' },
        ],
        departmentType:[
          { required: true, message: '请您选择部门类型！', trigger: 'blur' },
        ]
      }
    }
  },
  methods: {
    // table多选存储容器
    handleSelectionChange(valueSelected) {
      console.log(valueSelected)
      // 存储选中的数据至 => this.multipleSelection
      this.multipleSelection = valueSelected;
    },
    // 跨分页保持批量选中 row.key 依赖方法
    getRowkeys:function (row){
      return row.id;
    },
    // 页面显示
    page(currentPage){
      const _this = this
      if(_this.ruleForm.value === ''){
        axios.get('https://rzgxxt.top:8181/department/list/'+currentPage+'/'+_this.pageSize).then(function (resp) {
          _this.tableData = resp.data.data.data
          _this.total = resp.data.data.total
        })
      } else {
        _this.ruleForm.page = _this.currentPage
        axios.get('https://rzgxxt.top:8181/department/search',{params:_this.ruleForm}).then(function (resp) {
          _this.tableData = resp.data.data.data
          _this.total = resp.data.data.total
        })
      }
    },
    // 添加 - 部门
    add: function (addRuleForm) {
      const _this = this
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          axios.post('https://rzgxxt.top:8181/department/add', _this.addRuleForm).then(function (resp) {
            if (resp.data.code === 0) {
              _this.$alert('添加成功', '', {
                confirmButtonText: '确定',
                callback: action => {
                  location.reload()
                }
              });
            }
            if (resp.data.code === -1){
              _this.$alert('添加失败', '', {
                confirmButtonText: '添加失败了，请重新添加或联系系统管理员',
                callback: action => {
                  location.reload()
                }
              });
            }
            if(resp.data.code === 2){
              _this.$alert('该部门已存在', '', {
                confirmButtonText: '确认',
                callback: action => {
                  location.reload()
                }
              });
            }
          })
        }
      });
    },
    // 编辑 - 管材
    edit: function (editRuleForm) {
      const _this = this
      this.$refs.editFormRef.validate((valid) => {
        if (valid) {
          console.log(_this.editRuleForm)
          axios.put('https://rzgxxt.top:8181/department/update',_this.editRuleForm).then(function (resp) {
            if(resp.data.code === 0){
              _this.$alert('修改成功', '', {
                confirmButtonText: '确定',
                callback: action => {
                  location.reload()
                }
              });
            } if (resp.data.code === -1){
              _this.$alert('添加失败', '', {
                confirmButtonText: '添加失败了，请重新添加或联系系统管理员',
                callback: action => {
                  location.reload()
                }
              });
            } if (resp.data.code === 2){
              _this.$alert('该部门已存在', '', {
                confirmButtonText: '确认',
                callback: action => {
                  location.reload()
                }
              });
            }
          })
        }
      });
    },
    // 编辑 信息回填
    ifEdit: function (row) {
      console.log(row.ID)
      this.editRuleForm.departmentName = row.departmentName
      this.editRuleForm.departmentType = row.departmentType
      this.editRuleForm.id = row.id
      // 编辑 窗口打开
      this.editDialogVisible = true
    },
    // 搜索 - 部门
    submitForm(formName) {
      const _this = this
      // 每次搜索前，重置日期选择器
      if (this.ruleForm.date===null) {
        this.ruleForm.date = ''
        this.ruleForm.endTime = ''
        this.ruleForm.startTime = ''
      }
      // 让翻页复原
      _this.currentPage = 1
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          _this.ruleForm.page = _this.currentPage
          console.log(this.ruleForm)
          axios.get('https://rzgxxt.top:8181/department/search', {params:_this.ruleForm}).then(function (resp) {
            console.log(resp)
            _this.tableData = resp.data.data.data
            _this.total = resp.data.data.total
          })
        }
      });
    },
    // 搜索 - 日期获取器
    datePicker(picker) {
      this.ruleForm.startTime = picker[0].toString()
      this.ruleForm.endTime = picker[1].toString()
      // console.log(this.ruleForm.startTime)
      // console.log(this.ruleForm.endTime)
    },
    // 监听添加用户对话框关闭 -> 重置对话框内的内容
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    // 监听编辑 对话框关闭 -> 重置对话框内的内容
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
    // 删除
    deleteSelectItem(valueSelected) {
      const _this = this
      let ids = this.multipleSelection.map(v => v.id)//存储选中的数据
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        console.log(ids)
        axios.post('https://rzgxxt.top:8181/department/deleteByIds', ids).then(function (resp) {
          if (resp.data.code === 0) {
            _this.$alert('删除操作完成！（若部门当前人数不为0，则此部门无法删除）', '', {
              confirmButtonText: '确定',
              callback: action => {
                location.reload()
              }
            });
          } else {
            _this.$alert('删除失败', '', {
              confirmButtonText: '删除失败了，请重新删除或联系系统管理员',
              callback: action => {
                location.reload()
              }
            });
          }
        });
      })
    }
  },

  // 分页
  created() {
    const _this = this
    axios.get('https://rzgxxt.top:8181/department/list/1/'+_this.pageSize).then(function (resp) {
      _this.tableData = resp.data.data.data
      _this.total = resp.data.data.total
    })
  },
}
</script>

<style scoped>
/*主体*/
.el-main {
  padding-top: 0;
  width: 86vw;
  height: auto;
  background-color: #ecf5ff;
}
/*搜索框和按钮的父容器*/
.top {
  padding-top: 5vh;
  height: 5vh;
  width: 100%;
  justify-content: center;
}
/* 搜索框样式 */
.demo-input-size {
  height: auto;
  width: auto;
  overflow: hidden;
  border-radius: 20px;
  float: right;
}
</style>