import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/css/reset.css'

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta.needLogin) {
    //页面是否登录
    if (localStorage.getItem("token")) {
      //本地存储中是否有token(uid)数据
      next();
    } else {
      //next可以传递一个路由对象作为参数 表示需要跳转到的页面
      next({
        name:"Login"
      });
    }
  } else {
    //表示不需要登录
    next(); //继续往后走
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
