<template>
  <el-container>
    <el-main style="padding-top: 0">
      <router-view></router-view>
      <div>
        <!-- 按钮区域 -->
        <el-row class="mb-4" style="float: left; margin-top: 15px; margin-bottom: 15px">
          <el-button type="success" style="width: 130px; border-radius: 20px" plain @click="addDialogVisible=true">开始指派</el-button>
          <el-button type="danger" style="width: 100px; border-radius: 20px" plain @click="deleteSelectItem(multipleSelection)">删除</el-button>
        </el-row>
        <!-- el-form结构 -->
        <el-form ref="ruleFormRef" :model="ruleForm">
          <!-- 选择查询参数下拉框 -->
          <el-form-item class="mb-4" style="float: left;margin-top: 15px;margin-bottom: 15px;margin-left: 15px;">
            <el-select v-model="ruleForm.key" placeholder="请选择查询参数">
              <el-option label="订单号" value="id"></el-option>
              <el-option label="发送部门" value="FROM_DEPARTMENT"></el-option>
              <el-option label="接收部门" value="TO_DEPARTMENT"></el-option>
            </el-select>
          </el-form-item>
          <!-- 搜索框 & 搜索按钮 -->
          <el-form-item class="mb-4" style="float: right; margin-top: 15px; margin-bottom: 15px">
            <el-button @click="submitForm('ruleForm')" type="plain" style="margin-left: 10px; border-radius: 20px; float: right">搜索</el-button>
            <input v-model="ruleForm.value" style="height:36px;border: 1px solid rgb(197, 197, 197);float: right;border-radius: 20px;padding-left: 15px;" />
          </el-form-item>
          <!-- 日期区间 级联 -->
          <el-form-item class="mb-4" style="margin: 15px;float: right;">
            <div class="block">
              <el-date-picker
                  v-model="ruleForm.date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  @change="datePicker"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <!-- 新增 -->
      <el-dialog title="新增指派单" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
        <el-form ref="addFormRef" :model="addRuleForm" :rules="rules" label-width="130px">
          <el-form-item label="发送部门类型" prop="departmentFromType" label-width="130px">
            <el-select filterable v-model="addRuleForm.departmentFromType" @change="getAvailableFromDepartment(addRuleForm.departmentFromType)" placeholder="请选择">
              <el-option
                  v-for="item in departmentFromTypeList"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发送部门" prop="fromDepartment" label-width="130px">
            <el-select filterable v-model="addRuleForm.fromDepartment" placeholder="请选择发送部门...">
              <el-option
                  v-for="item in departmentFromList"
                  :label="item.departmentName"
                  :value="item.departmentName">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="接收部门类型" prop="departmentToType" label-width="130px">
            <el-select filterable v-model="addRuleForm.departmentToType" @change="getAvailableToDepartment(addRuleForm.departmentToType)" placeholder="请选择">
              <el-option
                  v-for="item in departmentToTypeList"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="接收部门" prop="toDepartment" label-width="130px">
            <el-select filterable v-model="addRuleForm.toDepartment" placeholder="请选择接收部门...">
              <el-option
                  v-for="item in departmentToList"
                  :label="item.departmentName"
                  :value="item.departmentName">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="add('addRuleForm')">确 定</el-button>
            </span>
      </el-dialog>

      <!-- table -->
      <div style="border-radius: 20px;overflow: hidden;display: block;height: 82.5vh;width: 100%;">
        <el-table stripe ref="multipleTableRef" :data="tableData" height="88vh" style="width: 100%"
                  @selection-change="handleSelectionChange" :row-key="getRowkeys">
          <el-table-column type="selection" :reserve-selection=true width="55" />
          <el-table-column prop="id" label="指派号" sortable width="100px"/>
          <el-table-column prop="fromDepartment" label="发送部门" sortable width="auto"/>
          <el-table-column prop="toDepartment" label="接收部门" sortable width="auto"/>
          <el-table-column prop="orderTime" label="指派时间" sortable width="auto"/>
        </el-table>
      </div>

      <!-- 分页 -->
      <el-pagination style="margin-top: 20px;float: right"
                     background
                     layout="prev, pager, next"
                     :page-size="pageSize"
                     :total="total"
                     :current-page.sync="currentPage"
                     @current-change="page">
      </el-pagination>
    </el-main>
  </el-container>
</template>

<script>
import {pipeManagerDate} from "@/assets/js/checkjs/pipeManagerChecker";

export default {
  data () {
    return {
      // 多选容器
      tableData: [],
      // 新增 - 部门级联选择 - From
      departmentFromList: [],
      departmentFromTypeList: [{
        value: '库房',
        label: '库房'
      }, {
        value: '运输部门类型',
        label: '运输部门类型'
      }, {
        value: '施工队',
        label: '施工队'
      }, {
        value: '具体井号',
        label: '具体井号'
      }],
      // 新增 - 部门级联选择 - To
      departmentToList: [],
      departmentToTypeList: [{
        value: '库房',
        label: '库房'
      }, {
        value: '运输部门类型',
        label: '运输部门类型'
      }, {
        value: '施工队',
        label: '施工队'
      }, {
        value: '具体井号',
        label: '具体井号'
      }],
      multipleSelection: [],
      // currentPage：当前在多少页
      currentPage: 1,
      // pageSize：每页展示多少条数据
      pageSize: 14,
      // total：总数据量
      total: null,
      key: '',
      value: '',
      // 搜索
      ruleForm: {
        startTime: '',
        endTime: '',
        key: '',
        value: '',
        page: '',
        size: 14,
      },
      // 新增
      addRuleForm: {
        departmentFromType: '',
        departmentToType: '',
        fromDepartment: '',
        toDepartment: '',
      },
      addDialogVisible: false,
      // 表单校验
      rules:{
        fromDepartment:[
          { required: true, message: '请您选择发送部门！', trigger: 'blur' },
        ],
        departmentFromType:[
          { required: true, message: '请您选择发送部门类型！', trigger: 'blur' },
        ],
        toDepartment:[
          { required: true, message: '请您选择接收部门！', trigger: 'blur' },
        ],
        departmentToType:[
          { required: true, message: '请您选择接收部门类型！', trigger: 'blur' },
        ],
      }
    }
  },
  methods: {
    // 获取部门
    getAvailableFromDepartment(departmentType){
      this.addRuleForm.fromDepartment = '';
      const _this = this
      axios.get('https://rzgxxt.top:8181/department/getDepartmentList/' + this.addRuleForm.departmentFromType).then(function (resp) {
        _this.departmentFromList = resp.data.data
      })
    },
    // 获取部门
    getAvailableToDepartment(departmentType){
      this.addRuleForm.toDepartment = '';
      const _this = this
      axios.get('https://rzgxxt.top:8181/department/getDepartmentList/' + this.addRuleForm.departmentToType).then(function (resp) {
        _this.departmentToList = resp.data.data
      })
    },
    // table多选存储容器
    handleSelectionChange(valueSelected) {
      // 存储选中的数据至 => this.multipleSelection
      this.multipleSelection = valueSelected;
    },
    // 跨分页保持批量选中 row.key 依赖方法
    getRowkeys:function (row){
      return row.id;
    },
    // 页面显示
    page(currentPage){
      const _this = this
      if(_this.ruleForm.value === ''){
        axios.get('https://rzgxxt.top:8181/distribute/list/'+currentPage+'/'+_this.pageSize).then(function (resp) {
          _this.tableData = resp.data.data.data
          _this.total = resp.data.data.total
        })
      } else {
        _this.ruleForm.page = _this.currentPage
        axios.get('https://rzgxxt.top:8181/distribute/search',{params:_this.ruleForm}).then(function (resp) {
          _this.tableData = resp.data.data.data
          _this.total = resp.data.data.total
        })
      }
    },
    // 添加 - 指派
    add: function (formName) {
      const _this = this
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          axios.post('https://rzgxxt.top:8181/distribute/add', _this.addRuleForm).then(function (resp) {
            if (resp.data.code === 0) {
              _this.$alert('指派下单成功', '', {
                confirmButtonText: '确定',
                callback: action => {
                  location.reload()
                }
              });
            } else {
              _this.$alert('指派下单失败', '', {
                confirmButtonText: '指派失败了，请重新添加或联系系统管理员',
                callback: action => {
                  location.reload()
                }
              });
            }
          })
        }
      });
    },
    // 搜索 - 指派
    submitForm(formName) {
      const _this = this
      // 每次搜索前，重置日期选择器
      if (this.ruleForm.date===null) {
        this.ruleForm.date = ''
        this.ruleForm.endTime = ''
        this.ruleForm.startTime = ''
      }
      //让翻页复原
      _this.currentPage = 1
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          _this.ruleForm.page = _this.currentPage
          console.log(this.ruleForm)
          axios.get('https://rzgxxt.top:8181/distribute/search',{params:_this.ruleForm}).then(function (resp) {
            _this.tableData = resp.data.data.data
            _this.total = resp.data.data.total
          })
        }
      });
    },
    // 搜索 - 日期获取器
    datePicker(picker) {
      this.ruleForm.startTime = picker[0].toString()
      this.ruleForm.endTime = picker[1].toString()
    },
    // 监听添加用户对话框关闭 -> 重置对话框内的内容
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    // 删除按钮点击触发
    deleteSelectItem(valueSelected) {
      const _this = this
      // 存储选中的数据
      let ids = this.multipleSelection.map(v => v.id)
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        console.log(ids)
        axios.post('https://rzgxxt.top:8181/distribute/deleteByIds', ids).then(function (resp) {
          if (resp.data.code === 0) {
            _this.$alert('删除成功', '', {
              confirmButtonText: '确定',
              callback: action => {
                location.reload()
              }
            });
          } else {
            _this.$alert('删除失败', '', {
              confirmButtonText: '删除失败了，请重新删除或联系系统管理员',
              callback: action => {
                location.reload()
              }
            });
          }
        });
      })
    },
  },

  // 钩子函数
  created() {
    const _this = this
    // 初始化页面数据
    axios.get('https://rzgxxt.top:8181/distribute/list/1/'+_this.pageSize).then(function (resp) {
      console.log(resp.data.data.data)
      _this.tableData = resp.data.data.data
      _this.total = resp.data.data.total
    })
    // 初始化新增选择部门列表 departmentList
    axios.get('https://rzgxxt.top:8181/distribute/getAvailableDepartmentList').then(function (resp) {
      console.log(resp.data.data)
      _this.departmentList = resp.data.data
    })
  },
}
</script>

<style scoped>
/*主体*/
.el-main {
  padding-top: 0;
  width: 86vw;
  height: auto;
  background-color: #ecf5ff;
}
/*搜索框和按钮的父容器*/
.top {
  padding-top: 5vh;
  height: 5vh;
  width: 100%;
  justify-content: center;
}
/* 搜索框样式 */
.demo-input-size {
  height: auto;
  width: auto;
  overflow: hidden;
  border-radius: 20px;
  float: right;
}
</style>